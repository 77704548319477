import React from 'react';
import algoliasearch from 'algoliasearch/lite';

export const AlgoliaContext = React.createContext();
const AlgoliaProvider = ({ children }) => {
    const searchClient = algoliasearch(
        process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
        process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY,
    );
    const defaultIndex = 'explore_hosts';
    return <AlgoliaContext.Provider value={{ searchClient, defaultIndex }}>{children}</AlgoliaContext.Provider>;
};
export default AlgoliaProvider;
