import React from 'react';
import { useSelector } from 'react-redux';
import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const ApolloAuthorizedProvider = ({ children }) => {
    const accessToken = useSelector((state) => state.accessToken);

    const httpLink = new HttpLink({
        uri: process.env.REACT_APP_API_URL,
    });

    const authLink = setContext((_, { headers, ...rest }) => {
        if (!accessToken) {
            return { headers, ...rest };
        }

        return {
            ...rest,
            headers: {
                ...headers,
                authorization: `Bearer ${accessToken}`,
            },
        };
    });
    const client = new ApolloClient({
        cache: new InMemoryCache(),
        link: authLink.concat(httpLink),
        connectToDevTools: true,
    });
    return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloAuthorizedProvider;
