import { withLDProvider } from 'launchdarkly-react-client-sdk';

const featureFlagProvider = withLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID,
    options: {
        bootstrap: 'localStorage',
    },
});

export default featureFlagProvider;
