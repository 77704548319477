import React from 'react';
import { ToastContainer, Slide } from 'react-toastify';

import './style.scss';
import iconCross from '../../../assets/svgs/icon-cross.svg';
import iconSuccess from '../../../assets/svgs/success-icon.svg';

const Toast = () => (
    <ToastContainer 
        position="bottom-center"
        autoClose={5000}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        hideProgressBar
        className="toast-custom"
        bodyClassName="toast-custom__body"
        toastClassName="toast-custom__wrapper"
        transition={Slide}
        icon={(type) => {
            if (type.type === 'success') {
                return (
                    <img src={iconSuccess} alt="success" />
                );
            }
                            
            if (type.type === 'error') {
                return (
                    <img src={iconCross} alt="cross" />
                );
            }

            return false;
        }}
    />
);

export default Toast;
