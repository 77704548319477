import React from 'react';
import { Oval } from 'react-loader-spinner';
import PropTypes from 'prop-types';

import variables from '../../../variables.module.scss';

const Loader = ({ height }) => (
    <Oval
        height={80}
        width={80}
        color={variables.blackRussian}
        wrapperStyle={{ justifyContent: 'center', height: `${height}` }}
        style={{ alignSelf: 'center' }}
        visible
        ariaLabel="oval-loading"
        secondaryColor={variables.zircon}
        strokeWidth={2}
        strokeWidthSecondary={2}
    />
);

export default Loader;
Loader.defaultProps = {
    height: undefined,
};

Loader.propTypes = {
    height: PropTypes.string,
};
