import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { StreamChat } from 'stream-chat';
import { createTheme, ThemeProvider } from '@material-ui/core';
import * as Sentry from '@sentry/react';

import Loader from '../components/common/loader-spinner/loader';
import lazyRetry from '../utils/chunk-loading-error-prevent';

const Header = lazy(() => lazyRetry(() => import('../components/header'), 'header'));
const MenuPanel = lazy(() => lazyRetry(() => import('../components/menu-panel'), 'menuPanel'));
const MenuPanelMobile = lazy(() => lazyRetry(() => import('../components/menu-panel-mobile'), 'menuPanelMobile'));
const Footer = lazy(() => lazyRetry(() => import('../components/footer'), 'footer'));
const HomePage = lazy(() => lazyRetry(() => import('./home-page'), 'homePage'));
const Account = lazy(() => lazyRetry(() => import('./settings'), 'settings'));
const Dashboard = lazy(() => lazyRetry(() => import('./dashboard'), 'dashboard'));
const Calendar = lazy(() => lazyRetry(() => import('./calendar'), 'calendar'));
const EditProfile = lazy(() => lazyRetry(() => import('./edit-profile'), 'editProfile'));
const NotFoundPage = lazy(() => lazyRetry(() => import('./not-found-page'), 'notFoundPage'));
const LoginPage = lazy(() => lazyRetry(() => import('./login-page'), 'loginPage'));
const ConfirmPage = lazy(() => lazyRetry(() => import('./confirm-page'), 'confirmPage'));
const SignUpPage = lazy(() => lazyRetry(() => import('./signup-page'), 'signUpPage'));
const SignUpRefer = lazy(() => lazyRetry(() => import('./signup-with-refer'), 'signUpRefer'));
const ForgotPasswordPage = lazy(() => lazyRetry(() => import('./forgot-password-page'), 'forgotPasswordPage'));
const Services = lazy(() => lazyRetry(() => import('./services-page'), 'services'));
const SchedulesPage = lazy(() => lazyRetry(() => import('./schedules-page'), 'schedulesPage'));
const PrivacyPage = lazy(() => lazyRetry(() => import('./privacy-page'), 'privacyPage'));
const TermsPage = lazy(() => lazyRetry(() => import('./terms-page'), 'termsPage'));
const Meets = lazy(() => lazyRetry(() => import('./meets-page'), 'meets'));
const Classes = lazy(() => lazyRetry(() => import('./classes'), 'classes'));
const Coupons = lazy(() => lazyRetry(() => import('./coupons-page'), 'couponsPage'));
const GoogleCalendarCallbackPage = lazy(() => lazyRetry(() => import('./google-calendar-callback-page'), 'calendarCallback'));
const Messages = lazy(() => lazyRetry(() => import('./messages'), 'messages'));
const CallBackPage = lazy(() => lazyRetry(() => import('./callback-page'), 'callbackPage'));
const ZoomCallbackPage = lazy(() => lazyRetry(() => import('./zoom-callback-page'), 'zoomCallbackPage'));
const CalendarPage = lazy(() => lazyRetry(() => import('./calendar-page'), 'calendarPage'));
const ExplorePage = lazy(() => lazyRetry(() => import('./explore'), 'explorePage'));
const ResetPasswordPage = lazy(() => lazyRetry(() => import('./reset-password'), 'resetPasswordPage'));
const CannyFeedback = lazy(() => lazyRetry(() => import('./feedback'), 'cannyFeedbackPage'));
const Favorites = lazy(() => lazyRetry(() => import('./favorites'), 'favorites'));
const InsightsPage = lazy(() => lazyRetry(() => import('./insights-page'), 'insightsPage'));
const Logout = lazy(() => lazyRetry(() => import('./logout'), 'logout'));
const Notifications = lazy(() => lazyRetry(() => import('./notifications'), 'notifications'));
const Review = lazy(() => lazyRetry(() => import('./reviews-page/review'), 'reviewsPage'));
const ErrorPage = lazy(() => lazyRetry(() => import('./error-page'), 'errorPage'));
const AddPayoutMethod = lazy(() => lazyRetry(() => import('./settings/components/payouts/add-payout-method'), 'add-payout'));
const SuggestLoginWithEmail = lazy(() => lazyRetry(() => import('./suggest-login-with-email'), 'suggestWithEmail'));
const SuggestLoginWithSocial = lazy(() => lazyRetry(() => import('./suggest-login-with-social'), 'suggestWithSocial'));
const DateTimeWidget = lazy(() => lazyRetry(() => import('./host-profile/components/date-time-widget/index'), 'dateTimeWidget'));
const ClassesPage = lazy(() => lazyRetry(() => import('./classes-page'), 'classes-page'));
const ReviewLessonBookingPage = lazy(() => lazyRetry(() => import('./review-lesson-booking-page'), 'review-classes-page'));
const Library = lazy(() => lazyRetry(() => import('./libray'), 'library'));
const history = createBrowserHistory();
const client = StreamChat.getInstance(process.env.REACT_APP_STREAM_API_KEY);
const theme = createTheme({
    radio: {
        fontFamily: 'Inter',
    },
    select: {
        fontFamily: 'Inter',
    },
    typography: {
        fontFamily: 'Inter',
    },
});
const SentryRoute = Sentry.withSentryRouting(Route);
const Routes = () => (
    <Router history={history}>

        <ThemeProvider theme={theme}>
            <SentryRoute path="*">
                <Suspense fallback={<div />}>
                    <Header client={client} />
                </Suspense>
            </SentryRoute>
            <SentryRoute
                path={[
                    '/services',
                    '/classes',
                    '/schedules', 
                    '/meets', 
                    '/account', 
                    '/messages', 
                    '/calendar', 
                    '/account/calendar', 
                    '/insights', 
                    '/coupons',
                    '/library',
                ]}
            >
                <Suspense fallback={<div />}>
                    <MenuPanel client={client} />
                </Suspense>
            </SentryRoute>

            <Suspense fallback={<Loader />}>
                <Switch>

                    <SentryRoute path="/" exact>
                        <HomePage />
                    </SentryRoute>
                    <SentryRoute path="/404" exact>
                        <NotFoundPage />
                    </SentryRoute>
                    <SentryRoute path="/callback" exact>
                        <CallBackPage />
                    </SentryRoute>
                    <SentryRoute path="/login" exact>
                        <LoginPage />
                    </SentryRoute>
                    <SentryRoute path="/suggest-login-with-social" exact>
                        <SuggestLoginWithSocial />
                    </SentryRoute>
                    <SentryRoute path="/suggest-login-with-email">
                        <SuggestLoginWithEmail />
                    </SentryRoute>
                    <SentryRoute path="/signup" exact>
                        <SignUpPage />
                    </SentryRoute>
                    <SentryRoute path="/r/:username">
                        <SignUpRefer />
                    </SentryRoute>
                    <SentryRoute path="/password" exact>
                        <ForgotPasswordPage />
                    </SentryRoute>
                    <SentryRoute path="/reset-password/:token" exact>
                        <ResetPasswordPage />
                    </SentryRoute>
                    <SentryRoute path="/legal/privacy" exact>
                        <PrivacyPage />
                    </SentryRoute>
                    <SentryRoute path="/legal/terms" exact>
                        <TermsPage />
                    </SentryRoute>
                    <SentryRoute path="/google-calendar/callback/success" exact>
                        <GoogleCalendarCallbackPage />
                    </SentryRoute>
                    <SentryRoute path="/zoom/callback/success" exact component={() => <ZoomCallbackPage />} />

                    <SentryRoute path="/services" exact>
                        <Services />
                    </SentryRoute>
                    <SentryRoute path="/schedules" exact>
                        <SchedulesPage />
                    </SentryRoute>
                    <SentryRoute path="/classes" exact>
                        <Classes />
                    </SentryRoute>

                    <SentryRoute path="/classes/reviews/:id">
                        <ReviewLessonBookingPage />
                    </SentryRoute>
                    <SentryRoute path="/meets" exact>
                        <Meets />
                    </SentryRoute>
                    <SentryRoute path="/messages" exact>
                        <Messages client={client} />
                    </SentryRoute>
                    <SentryRoute path="/explore" exact>
                        <ExplorePage client={client} />
                    </SentryRoute>
                    <SentryRoute path="/calendar" exact>
                        <Calendar />
                    </SentryRoute>

                    <SentryRoute path="/coupons" exact>
                        <Coupons />
                    </SentryRoute>
                    <SentryRoute path="/library" exact>
                        <Library />
                    </SentryRoute>
                    <SentryRoute path="/insights">
                        <InsightsPage />
                    </SentryRoute>
                    <SentryRoute path="/notifications" exact>
                        <Notifications />
                    </SentryRoute>
                    <SentryRoute path="/feedback/feature-requests" exact>
                        <CannyFeedback />
                    </SentryRoute>
                    <SentryRoute path="/favorites" exact>
                        <Favorites client={client} />
                    </SentryRoute>
                    <SentryRoute path="/logout" exact>
                        <Logout />
                    </SentryRoute>
                    <SentryRoute path="/account" exact>
                        <Account />
                    </SentryRoute>
                    <SentryRoute path="/account/payout" exact>
                        <AddPayoutMethod />
                    </SentryRoute>
                    <SentryRoute path="/account/calendar" component={() => <CalendarPage />} />
                    <SentryRoute path="/confirm" component={() => <ConfirmPage />} />
                    <SentryRoute path="/dashboard" component={() => <Dashboard />} />
                    <SentryRoute path="/account" component={() => <Account />} />
                    <SentryRoute path="/availability" component={() => <Calendar />} />
                    <SentryRoute path="/authorization-error" component={() => <ErrorPage />} />
                    <SentryRoute path="/reviews/:id">
                        <Review />
                    </SentryRoute>
                    <SentryRoute path="/edit-profile" component={() => <EditProfile />} />
                    <SentryRoute path="/:slug/checkout" component={() => <ConfirmPage />} />
                    <SentryRoute path="/:slug/:id" component={() => <ClassesPage />} />
                    <SentryRoute path="/:slug" component={() => <DateTimeWidget />} />

                </Switch>
                <MenuPanelMobile client={client} />

            </Suspense>
            <Suspense fallback={<div />}>
                <Footer />
            </Suspense>

        </ThemeProvider>
    </Router>
);

export default Routes;
