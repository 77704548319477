import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { PersistGate } from 'redux-persist/integration/react';
import aa from 'search-insights';
import { Replay } from '@sentry/replay';

import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ApolloAuthorizedProvider from './Apollo/apolllo-authorized-provider';
import { store, persistor } from './redux/store';
import AlgoliaProvider from './providers/algolia.context';

aa('init', {
    appId: process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
    apiKey: process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY,
});
const history = createBrowserHistory();
Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
        new BrowserTracing({
            tracingOrigins: ['popless.com'],
            routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
        }),
        new Replay({
            replaysSamplingRate:  1,
            stickySession: true,
        }),
    ],
    environment: process.env.REACT_APP_ENVIRONMENT,
    tracesSampleRate: 1.0,
    release: 'popless@0.8.58',
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
});


ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <AlgoliaProvider>
                <ApolloAuthorizedProvider>
                    <App />
                </ApolloAuthorizedProvider>
            </AlgoliaProvider>
        </PersistGate>
    </Provider>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
