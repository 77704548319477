const defaultState = {
    accessToken: '',
    refreshToken: '',
    tokenExpiresAt: 0,
    isLoginModalOpen: false,
    isSignupModalOpen: false,
};

const tokenReducer = (state = defaultState, action) => {
    switch (action.type) {
        case 'STORE_TOKENS':
            return ({
                accessToken: action.accessToken,
                refreshToken: action.refreshToken,
                tokenExpiresAt: action.tokenExpiresAt,
            });
        case 'TOGGLE_LOGIN_MODAL':
            return ({
                isLoginModalOpen: action.isLoginModalOpen,
                isSignupModalOpen: false,
            });
        case 'TOGGLE_SIGNUP_MODAL':
            return ({
                isSignupModalOpen: action.isSignupModalOpen,
                isLoginModalOpen: false,
            });
        default:
            return state;
    }
};

export default tokenReducer;
