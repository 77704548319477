import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useEffect } from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { Helmet } from 'react-helmet';
import 'react-toastify/dist/ReactToastify.css';
import * as Sentry from '@sentry/react';
import TagManager from 'react-gtm-module';
import analytics from 'react-segment';

// import CookieConsent from 'react-cookie-consent';
import Routes from './routes';
import featureFlagProvider from './feature-flag';
import Toast from './components/common/toast/Toast';

const googleMapsURL = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAPS_API_KEY}&libraries=places`;

function App() {
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
    const getCookie = (name) => document.cookie.split(';').find((c) => c.trim().startsWith(`${name}=`));

    useEffect(() => {
        const dataLayerDetails = JSON.parse(sessionStorage.getItem('dataLayerDetails'));
        // eslint-disable-next-line no-unused-vars
        const { REACT_APP_GTM_AUTH, REACT_APP_GTM_PREVIEW, REACT_APP_GTM_ID, REACT_APP_SEGMENT_WRITE_KEY } = process.env;
        const tagManagerArgs = {
            gtmId: REACT_APP_GTM_ID,
            auth: REACT_APP_GTM_AUTH,
            preview: REACT_APP_GTM_PREVIEW,
            dataLayer: dataLayerDetails,
        };
        TagManager.initialize(tagManagerArgs);
        getCookie('hasUserAllowedCookies') || (document.cookie = 'allowCookies=true');
        analytics.default.load(REACT_APP_SEGMENT_WRITE_KEY);
    }, []);

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Elements stripe={stripePromise}>
                <div className="app">
                    <Helmet>
                        <script src={googleMapsURL} />
                    </Helmet>
                    {/* <CookieConsent
                        enableDeclineButton
                        location="top"
                        buttonText="I agree"
                        declineButtonText="I decline"
                        onDecline={() => {
                            document.cookie = 'allowCookies=false';
                        }}
                        cookieName="hasUserAllowedCookies"
                        style={{ fontFamily: 'Inter' }}
                        buttonStyle={{ borderRadius: '8px', padding: '10px 20px' }}
                        declineButtonStyle={{ borderRadius: '8px', padding: '10px 20px' }}
                    >
                                This website uses cookies to enhance the user experience.
                    </CookieConsent> */}
                    <Toast />
                    <Routes />
                </div>
            </Elements>
        </MuiPickersUtilsProvider>
    );
}

export default featureFlagProvider(Sentry.withProfiler(App));
